/*
* Vanilla Styles Bundle
* Author: Abdo Hamoud <abdo.hamoud@gmail.com>
* Website: https://www.abdo-host.com
* Company: https://www.tatwerat.com
*/
:root {
  --input-height: 38px;
  --awesomplete-hover-bg: var(--blue-100);
  --awesomplete-hover-color: var(--blue-600);
}

.vanilla-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .vanilla-col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.vanilla-col-1, .vanilla-col-2, .vanilla-col-3, .vanilla-col-4, .vanilla-col-5, .vanilla-col-6, .vanilla-col-7, .vanilla-col-8, .vanilla-col-9, .vanilla-col-10, .vanilla-col-11, .vanilla-col-12, .vanilla-col,
.vanilla-col-auto, .vanilla-col-sm-1, .vanilla-col-sm-2, .vanilla-col-sm-3, .vanilla-col-sm-4, .vanilla-col-sm-5, .vanilla-col-sm-6, .vanilla-col-sm-7, .vanilla-col-sm-8, .vanilla-col-sm-9, .vanilla-col-sm-10, .vanilla-col-sm-11, .vanilla-col-sm-12, .vanilla-col-sm,
.vanilla-col-sm-auto, .vanilla-col-md-1, .vanilla-col-md-2, .vanilla-col-md-3, .vanilla-col-md-4, .vanilla-col-md-5, .vanilla-col-md-6, .vanilla-col-md-7, .vanilla-col-md-8, .vanilla-col-md-9, .vanilla-col-md-10, .vanilla-col-md-11, .vanilla-col-md-12, .vanilla-col-md,
.vanilla-col-md-auto, .vanilla-col-lg-1, .vanilla-col-lg-2, .vanilla-col-lg-3, .vanilla-col-lg-4, .vanilla-col-lg-5, .vanilla-col-lg-6, .vanilla-col-lg-7, .vanilla-col-lg-8, .vanilla-col-lg-9, .vanilla-col-lg-10, .vanilla-col-lg-11, .vanilla-col-lg-12, .vanilla-col-lg,
.vanilla-col-lg-auto, .vanilla-col-xl-1, .vanilla-col-xl-2, .vanilla-col-xl-3, .vanilla-col-xl-4, .vanilla-col-xl-5, .vanilla-col-xl-6, .vanilla-col-xl-7, .vanilla-col-xl-8, .vanilla-col-xl-9, .vanilla-col-xl-10, .vanilla-col-xl-11, .vanilla-col-xl-12, .vanilla-col-xl,
.vanilla-col-xxl-1, .vanilla-col-xxl-2, .vanilla-col-xxl-3, .vanilla-col-xxl-4, .vanilla-col-xxl-5, .vanilla-col-xxl-6, .vanilla-col-xxl-7, .vanilla-col-xxl-8, .vanilla-col-xxl-9, .vanilla-col-xxl-10, .vanilla-col-xxl-11, .vanilla-colxxl-12, .vanilla-col-xxl,
.vanilla-col-xxxl-1, .vanilla-col-xxxl-2, .vanilla-col-xxxl-3, .vanilla-col-xxxl-4, .vanilla-col-xxxl-5, .vanilla-col-xxxl-6, .vanilla-col-xxxl-7, .vanilla-col-xxxl-8, .vanilla-col-xxxl-9, .vanilla-col-xxxl-10, .vanilla-col-xxxl-11, .vanilla-colxxl-12, .vanilla-col-xxl,
.vanilla-col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.vanilla-col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.vanilla-col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.vanilla-col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.vanilla-col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.vanilla-col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.vanilla-col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.vanilla-col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.vanilla-col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.vanilla-col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.vanilla-col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.vanilla-col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.vanilla-col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.vanilla-col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.vanilla-col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .vanilla-col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .vanilla-col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .vanilla-col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .vanilla-col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .vanilla-col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .vanilla-col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .vanilla-col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .vanilla-col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .vanilla-col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .vanilla-col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .vanilla-col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .vanilla-col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .vanilla-col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .vanilla-col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 768px) {
  .vanilla-col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .vanilla-col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .vanilla-col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .vanilla-col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .vanilla-col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .vanilla-col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .vanilla-col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .vanilla-col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .vanilla-col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .vanilla-col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .vanilla-col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .vanilla-col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .vanilla-col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .vanilla-col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 992px) {
  .vanilla-col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .vanilla-col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .vanilla-col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .vanilla-col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .vanilla-col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .vanilla-col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .vanilla-col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .vanilla-col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .vanilla-col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .vanilla-col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .vanilla-col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .vanilla-col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .vanilla-col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .vanilla-col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 1200px) {
  .vanilla-col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .vanilla-col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .vanilla-col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .vanilla-col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .vanilla-col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .vanilla-col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .vanilla-col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .vanilla-col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .vanilla-col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .vanilla-col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .vanilla-col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .vanilla-col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .vanilla-col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .vanilla-col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 1600px) {
  .vanilla-col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .vanilla-col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .vanilla-col-xxl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .vanilla-col-xxl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .vanilla-col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .vanilla-col-xxl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .vanilla-col-xxl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .vanilla-col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .vanilla-col-xxl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .vanilla-col-xxl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .vanilla-col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .vanilla-col-xxl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .vanilla-col-xxl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .vanilla-col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-last {
    order: 13;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-6 {
    order: 6;
  }
  .order-xxl-7 {
    order: 7;
  }
  .order-xxl-8 {
    order: 8;
  }
  .order-xxl-9 {
    order: 9;
  }
  .order-xxl-10 {
    order: 10;
  }
  .order-xxl-11 {
    order: 11;
  }
  .order-xxl-12 {
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 1920px) {
  .vanilla-col-xxxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .vanilla-col-xxxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .vanilla-col-xxxl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .vanilla-col-xxxl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .vanilla-col-xxxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .vanilla-col-xxxl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .vanilla-col-xxxl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .vanilla-col-xxxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .vanilla-col-xxxl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .vanilla-col-xxxl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .vanilla-col-xxxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .vanilla-col-xxxl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .vanilla-col-xxxl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .vanilla-col-xxxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxxl-first {
    order: -1;
  }
  .order-xxxl-last {
    order: 13;
  }
  .order-xxxl-0 {
    order: 0;
  }
  .order-xxxl-1 {
    order: 1;
  }
  .order-xxxl-2 {
    order: 2;
  }
  .order-xxxl-3 {
    order: 3;
  }
  .order-xxxl-4 {
    order: 4;
  }
  .order-xxxl-5 {
    order: 5;
  }
  .order-xxxl-6 {
    order: 6;
  }
  .order-xxxl-7 {
    order: 7;
  }
  .order-xxxl-8 {
    order: 8;
  }
  .order-xxxl-9 {
    order: 9;
  }
  .order-xxxl-10 {
    order: 10;
  }
  .order-xxxl-11 {
    order: 11;
  }
  .order-xxxl-12 {
    order: 12;
  }
  .offset-xxxl-0 {
    margin-left: 0;
  }
  .offset-xxxl-1 {
    margin-left: 8.333333%;
  }
  .offset-xxxl-2 {
    margin-left: 16.666667%;
  }
  .offset-xxxl-3 {
    margin-left: 25%;
  }
  .offset-xxxl-4 {
    margin-left: 33.333333%;
  }
  .offset-xxxl-5 {
    margin-left: 41.666667%;
  }
  .offset-xxxl-6 {
    margin-left: 50%;
  }
  .offset-xxxl-7 {
    margin-left: 58.333333%;
  }
  .offset-xxxl-8 {
    margin-left: 66.666667%;
  }
  .offset-xxxl-9 {
    margin-left: 75%;
  }
  .offset-xxxl-10 {
    margin-left: 83.333333%;
  }
  .offset-xxxl-11 {
    margin-left: 91.666667%;
  }
}
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.vanilla-row-sm {
  margin-left: -7.5px !important;
  margin-right: -7.5px !important;
}
.vanilla-row-sm .vanilla-col-1, .vanilla-row-sm .vanilla-col-2, .vanilla-row-sm .vanilla-col-3, .vanilla-row-sm .vanilla-col-4, .vanilla-row-sm .vanilla-col-5, .vanilla-row-sm .vanilla-col-6, .vanilla-row-sm .vanilla-col-7, .vanilla-row-sm .vanilla-col-8, .vanilla-row-sm .vanilla-col-9, .vanilla-row-sm .vanilla-col-10, .vanilla-row-sm .vanilla-col-11, .vanilla-row-sm .vanilla-col-12, .vanilla-row-sm .vanilla-col,
.vanilla-row-sm .vanilla-col-auto, .vanilla-row-sm .vanilla-col-sm-1, .vanilla-row-sm .vanilla-col-sm-2, .vanilla-row-sm .vanilla-col-sm-3, .vanilla-row-sm .vanilla-col-sm-4, .vanilla-row-sm .vanilla-col-sm-5, .vanilla-row-sm .vanilla-col-sm-6, .vanilla-row-sm .vanilla-col-sm-7, .vanilla-row-sm .vanilla-col-sm-8, .vanilla-row-sm .vanilla-col-sm-9, .vanilla-row-sm .vanilla-col-sm-10, .vanilla-row-sm .vanilla-col-sm-11, .vanilla-row-sm .vanilla-col-sm-12, .vanilla-row-sm .vanilla-col-sm,
.vanilla-row-sm .vanilla-col-sm-auto, .vanilla-row-sm .vanilla-col-md-1, .vanilla-row-sm .vanilla-col-md-2, .vanilla-row-sm .vanilla-col-md-3, .vanilla-row-sm .vanilla-col-md-4, .vanilla-row-sm .vanilla-col-md-5, .vanilla-row-sm .vanilla-col-md-6, .vanilla-row-sm .vanilla-col-md-7, .vanilla-row-sm .vanilla-col-md-8, .vanilla-row-sm .vanilla-col-md-9, .vanilla-row-sm .vanilla-col-md-10, .vanilla-row-sm .vanilla-col-md-11, .vanilla-row-sm .vanilla-col-md-12, .vanilla-row-sm .vanilla-col-md,
.vanilla-row-sm .vanilla-col-md-auto, .vanilla-row-sm .vanilla-col-lg-1, .vanilla-row-sm .vanilla-col-lg-2, .vanilla-row-sm .vanilla-col-lg-3, .vanilla-row-sm .vanilla-col-lg-4, .vanilla-row-sm .vanilla-col-lg-5, .vanilla-row-sm .vanilla-col-lg-6, .vanilla-row-sm .vanilla-col-lg-7, .vanilla-row-sm .vanilla-col-lg-8, .vanilla-row-sm .vanilla-col-lg-9, .vanilla-row-sm .vanilla-col-lg-10, .vanilla-row-sm .vanilla-col-lg-11, .vanilla-row-sm .vanilla-col-lg-12, .vanilla-row-sm .vanilla-col-lg,
.vanilla-row-sm .vanilla-col-lg-auto, .vanilla-row-sm .vanilla-col-xl-1, .vanilla-row-sm .vanilla-col-xl-2, .vanilla-row-sm .vanilla-col-xl-3, .vanilla-row-sm .vanilla-col-xl-4, .vanilla-row-sm .vanilla-col-xl-5, .vanilla-row-sm .vanilla-col-xl-6, .vanilla-row-sm .vanilla-col-xl-7, .vanilla-row-sm .vanilla-col-xl-8, .vanilla-row-sm .vanilla-col-xl-9, .vanilla-row-sm .vanilla-col-xl-10, .vanilla-row-sm .vanilla-col-xl-11, .vanilla-row-sm .vanilla-col-xl-12, .vanilla-row-sm .vanilla-col-xl,
.vanilla-row-sm .vanilla-col-xxxl-1, .vanilla-row-sm .vanilla-col-xxxl-2, .vanilla-row-sm .vanilla-col-xxxl-3, .vanilla-row-sm .vanilla-col-xxxl-4, .vanilla-row-sm .vanilla-col-xxxl-5, .vanilla-row-sm .vanilla-col-xxxl-6, .vanilla-row-sm .vanilla-col-xxxl-7, .vanilla-row-sm .vanilla-col-xxxl-8, .vanilla-row-sm .vanilla-col-xxxl-9, .vanilla-row-sm .vanilla-col-xxxl-10, .vanilla-row-sm .vanilla-col-xxxl-11, .vanilla-row-sm .vanilla-colxxl-12, .vanilla-row-sm .vanilla-col-xxl,
.vanilla-row-sm .vanilla-col-xl-auto {
  padding-left: 7.5px !important;
  padding-right: 7.5px !important;
  margin: 0px !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
*::-webkit-scrollbar-track, *::-webkit-scrollbar-corner {
  background: rgba(221, 221, 221, 0.8666666667);
}

*::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 6px;
}

body {
  overflow-x: hidden !important;
}

html, body {
  background-color: #fafafa;
}

@media (min-width: 768px) {
  body.full-width .container {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0px 20px !important;
  }
}
.btn-group .btn:not(:first-child), .btn-group div#driver-popover-item .driver-popover-footer button:not(:first-child), div#driver-popover-item .driver-popover-footer .btn-group button:not(:first-child) {
  margin-left: -1px;
}

[data-page-route=Workspaces] .layout-main .layout-main-section {
  padding: var(--padding-md);
  margin: 20px 0px;
}

.page-container {
  background-color: #fafafa;
}

.page-head {
  background: #f8fafb;
  border-bottom: 1px solid transparent;
  height: auto;
  padding: 12px 0px 14px 10px;
  top: var(--navbar-height) !important;
  z-index: 7;
}
.page-head:after {
  background: #f8fafb;
  height: 21px;
  position: absolute;
  bottom: -7px;
  left: 15px;
  right: 15px;
  z-index: 10;
  content: "";
}
.page-head .page-actions {
  background: #fff;
  border: 1px solid #d5d5d5;
  padding: 10px;
  border-radius: 7px;
  margin: 0px 15px 0px 5px;
  position: relative;
}

.page-head .page-head-content {
  height: auto;
}

.page-head.drop-shadow {
  box-shadow: none;
  background-color: #fff !important;
  border-bottom: 1px solid #ddd;
  z-index: 150;
  top: 74px !important;
  position: sticky;
  margin: 0px -20px;
  padding: 10px 20px;
}

.page-container[data-page-route=Workspaces] .layout-side-section, .page-container[data-page-route=Workspaces] .page-title .sidebar-toggle-btn {
  display: none !important;
}
.page-container[data-page-route=Workspaces] .layout-main-section-wrapper {
  width: 100% !important;
}

.centered.splash {
  background: #f8fafb;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transform: inherit;
  -webkit-transform: initial;
  text-align: center;
  vertical-align: middle;
  z-index: 10000000000000000;
}
.centered.splash img {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -50px 0px 0px -50px;
}

@media (min-width: 992px) {
  [data-page-route=Workspaces] .layout-main {
    height: auto;
  }
}
@media (min-width: 992px) {
  [data-page-route=Workspaces] .layout-main .layout-side-section, [data-page-route=Workspaces] .layout-main .layout-main-section-wrapper {
    height: auto;
    padding-right: 20px;
    padding-left: 20px;
  }
}
.list-sidebar .list-filters input {
  background: #ffffff;
  border: 1px solid #d0d0d0;
}

button {
  outline: none !important;
}

.btn {
  box-shadow: none !important;
  transition: all 0.2s ease-in-out;
  border-radius: 7px;
  outline: none !important;
}

button.btn.btn-default.btn-sm.btn-paging.btn-info {
  background-color: var(--gray-600) !important;
  border-color: var(--gray-600) !important;
  color: #fff !important;
}

.awesomplete .input-with-feedback {
  background-color: #ffffff;
  border: 2px solid #d2dbe1;
  padding: 0px 10px;
}

.like-disabled-input {
  background-color: #f2f4f6;
  line-height: 42px;
  color: #535556;
  padding: 0px 10px;
}

.form-control {
  background: #ffffff;
  border: 2px solid #d2dbe1;
  transition: all 0.2s ease-in-out;
  font-size: 16px;
  color: #535556;
  padding: 0px 10px;
  border-radius: 7px;
}
.form-control:hover {
  border-color: #bfc6cd;
}
.form-control:focus {
  background: #ffffff !important;
  border-color: var(--primary) !important;
}

.control-input[data-option=Email] > input, .control-input[data-option=Phone] > input {
  padding-left: 38px;
}
.control-input[data-option=Email]:before, .control-input[data-option=Phone]:before {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 11px;
  top: 7px;
  z-index: 1;
  display: block;
  font-size: 19px;
  font-weight: 300;
  font-family: "Font Awesome 5 Pro", serif;
  color: #818d95;
  content: "";
}
.control-input[data-option=Email]:before {
  content: "\f0e0";
}
.control-input[data-option=Phone]:before {
  content: "\f879";
  font-size: 17px;
  top: 8px;
}

.frappe-control[data-fieldtype=Date] .control-input, .frappe-control[data-fieldtype=Time] .control-input {
  position: relative;
}
.frappe-control[data-fieldtype=Date] .control-input > input, .frappe-control[data-fieldtype=Time] .control-input > input {
  padding-left: 38px;
}
.frappe-control[data-fieldtype=Date] .control-input:before, .frappe-control[data-fieldtype=Time] .control-input:before {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 11px;
  top: 7px;
  z-index: 1;
  display: block;
  font-size: 19px;
  font-weight: 300;
  font-family: "Font Awesome 5 Pro", serif;
  color: #818d95;
  content: "";
}
.frappe-control[data-fieldtype=Date] .control-input:before {
  content: "\f073";
}
.frappe-control[data-fieldtype=Time] .control-input:before {
  content: "\f017";
}

.awesomplete > [role=listbox] {
  border-color: var(--primary);
  margin-top: 5px;
}

.awesomplete > [role=listbox] > li .link-option, .awesomplete > [role=listbox] > [role=option] .link-option {
  font-weight: 700;
  color: var(--primary);
}

.frappe-control[data-fieldtype=Link] .awesomplete > input {
  width: calc(100% - 52px);
}
.frappe-control[data-fieldtype=Link] .action-btn, .frappe-control[data-fieldtype=Link] .link-btn {
  right: 60px !important;
}
.frappe-control[data-fieldtype=Link] .link-btn-add {
  position: absolute;
  top: 0;
  right: 0;
}
.frappe-control[data-fieldtype=Link] .link-btn-add .btn {
  width: 42px;
  height: 38px;
  line-height: 35px;
  font-size: 16px;
  color: #666666;
  padding: 0;
}
.frappe-control[data-fieldtype=Link] .link-btn-add .btn.btn-dark {
  background: var(--gray);
  border-color: var(--gray);
}
.frappe-control[data-fieldtype=Link] .link-btn-add .btn.btn-outline-dark {
  border: 2px solid var(--gray-400);
  box-shadow: none !important;
}
.frappe-control[data-fieldtype=Link] .link-btn-add .btn.btn-outline-dark:hover, .frappe-control[data-fieldtype=Link] .link-btn-add .btn.btn-outline-dark:active, .frappe-control[data-fieldtype=Link] .link-btn-add .btn.btn-outline-dark:focus {
  background: var(--gray-600);
  border-color: var(--gray-600);
  color: #ffffff;
}
.frappe-control[data-fieldtype=Link] a.btn-open.no-decoration {
  background: var(--gray);
  height: 24px;
  line-height: 24px;
  display: inline-block;
  padding: 0px 12px;
  font-size: 12px;
  color: #fff;
  border-radius: 5px;
  position: relative;
  top: 0px;
}
.frappe-control[data-fieldtype=Link] a.btn-open.no-decoration .icon {
  stroke: #ffffff;
}
.frappe-control[data-fieldtype=Link] .no-add-btn .action-btn, .frappe-control[data-fieldtype=Link] .no-add-btn .link-btn {
  right: 8px !important;
}
.frappe-control[data-fieldtype=Link] .no-add-btn > .awesomplete > input {
  width: 100%;
}

.grid-body .editable-row .frappe-control .action-btn, .grid-body .editable-row .frappe-control .link-btn {
  right: 6px !important;
}
.grid-body .editable-row .frappe-control[data-fieldtype=Link] .link-btn-add {
  display: none !important;
}
.grid-body .editable-row .form-control {
  width: 100% !important;
}

.frappe-control:not([data-fieldtype=MultiSelectPills]):not([data-fieldtype="Table MultiSelect"]).has-error input {
  border: 2px solid var(--error-border);
}

.awesomplete > [role=listbox] > li:hover, .awesomplete > [role=listbox] > li[aria-selected=true], .awesomplete > [role=listbox] > [role=option]:hover, .awesomplete > [role=listbox] > [role=option][aria-selected=true] {
  background-color: var(--awesomplete-hover-bg);
  color: var(--awesomplete-hover-color);
}

.frappe-control[data-fieldtype=Select] .form-control {
  cursor: pointer;
}

.frappe-control[data-fieldtype=Select] .control-input .select-icon, .frappe-control[data-fieldtype=Select].form-group .select-icon {
  top: 8px;
}

.frappe-control .action-btn, .frappe-control .link-btn {
  top: 6px;
  right: 8px;
}

.btn.btn-primary, div#driver-popover-item .driver-popover-footer button.btn-primary, div#driver-popover-item .driver-popover-footer button.driver-next-btn {
  background-color: var(--btn-primary);
  color: var(--neutral);
  white-space: nowrap;
  --icon-stroke: currentColor;
  --icon-fill-bg: var(--btn-primary);
}

.btn.btn-primary:active, div#driver-popover-item .driver-popover-footer button.btn-primary:active, div#driver-popover-item .driver-popover-footer button.driver-next-btn:active {
  color: var(--gray-100) !important;
  background-color: var(--blue-600) !important;
  box-shadow: none !important;
}

.btn-primary-light {
  background-color: var(--blue-100);
  border-color: var(--blue-100);
  color: var(--primary);
}

.btn.btn-default {
  border: 1px solid #d1d3d8 !important;
}
.btn.btn-default:hover {
  background: rgba(235, 239, 243, 0.7) !important;
  border-color: #a2a8b1 !important;
}

.page-form .form-control {
  height: 32px !important;
}
.page-form .frappe-control[data-fieldtype=Link] .awesomplete > input {
  width: 100% !important;
}
.page-form .frappe-control[data-fieldtype=Link] .link-btn-add {
  display: none !important;
}

.form-control.input-xs {
  height: 32px;
  font-size: var(--text-base) !important;
  font-weight: var(--weight-regular) !important;
}

.page-head .primary-action .icon {
  display: none !important;
}
.page-head .primary-action span.hidden-xs {
  display: inline-block !important;
}

.page-form {
  border-bottom: 1px solid var(--gray-300);
  border-radius: 7px 7px 0px 0px;
}

select.input-xs {
  line-height: 14px;
  padding: 0 12px;
  cursor: pointer;
}

.filter-field .frappe-control[data-fieldtype=Link] .awesomplete > input {
  width: 100%;
}
.filter-field .frappe-control[data-fieldtype=Link] .link-btn-add {
  display: none !important;
}

input[type=radio], input[type=checkbox] {
  cursor: pointer;
}

.frappe-control[data-fieldtype=Select] .control-input .placeholder.xs, .frappe-control[data-fieldtype=Select].form-group .placeholder.xs {
  top: 6px;
}

.indicator-pill.blue, .indicator-pill-right.blue, .indicator-pill-round.blue {
  background: #edf7ff;
  color: var(--blue-700);
}

.dropdown-item:hover, .dropdown-item:focus {
  background-color: #edf7ff;
  color: var(--blue-700);
}

.dropdown-item.active, .dropdown-item:active {
  background-color: var(--blue-600);
  color: #ffffff;
}

.frappe-list {
  padding: 0px !important;
}
.frappe-list .list-row-head {
  background: var(--blue-200);
  border: 1px solid var(--blue-400);
  cursor: default;
  height: 45px;
  font-size: 16px;
  font-weight: 700 !important;
  color: var(--blue-800) !important;
  margin: 10px;
  border-radius: 7px;
}
.frappe-list .list-row-head .level-item {
  font-weight: 700 !important;
}
.frappe-list .list-row-container {
  border: 1px solid var(--gray-300);
  margin: -1px 10px 0px 10px;
}
.frappe-list .result > .list-row-container:nth-of-type(2) {
  border-radius: 7px 7px 0px 0px;
  overflow: hidden;
}
.frappe-list .result > .list-row-container:last-of-type {
  border-radius: 0px 0px 7px 7px;
  overflow: hidden;
}
.frappe-list .result > .list-row-container:last-of-type:nth-of-type(2) {
  border-radius: 7px;
  overflow: hidden;
}

.list-row:hover:not(.list-row-head), .list-row-head:hover:not(.list-row-head) {
  background-color: var(--gray-50);
}

.report-summary .summary-value {
  height: auto;
  min-height: 30px;
}

#page-query-report .page-head {
  position: sticky;
}

.frappe-control[data-fieldtype=Color] .selected-color {
  top: 13px;
  left: 13px;
}

.alert.desk-alert .dv-alert-icon {
  margin: 0px;
}
.alert.desk-alert.dv-alert-success, .alert.desk-alert.dv-alert-green {
  border: 1px solid var(--success);
}
.alert.desk-alert.dv-alert-info, .alert.desk-alert.dv-alert-blue {
  border: 1px solid var(--info);
}
.alert.desk-alert.dv-alert-error, .alert.desk-alert.dv-alert-red {
  border: 1px solid var(--danger);
}
.alert.desk-alert.dv-alert-warning, .alert.desk-alert.dv-alert-orange, .alert.desk-alert.dv-alert-yellow {
  border: 1px solid var(--orange);
}

.alert-body {
  text-align: left;
  padding: 0px 15px 10px 15px;
}

.btn svg.icon.icon-sm {
  position: relative;
  top: -1px;
}

.layout-main {
  position: relative;
  margin-top: 6px;
}
.layout-main .btn-toggle-sidebar {
  background: #fff;
  border: 0;
  box-shadow: -2px 0px 5px -2px rgba(0, 0, 0, 0.1882352941);
  margin: 0px 15px 0px 0px;
  height: 30px;
  width: 20px;
  display: none;
  text-align: center;
  line-height: 29px;
  font-size: 16px;
  padding: 0;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 50;
  color: #666666;
  border-radius: 5px 0px 0px 5px;
  transition: all 0.2s ease-in;
}
.layout-main .btn-toggle-sidebar.sidebar-shown > i {
  display: inline-block;
  transform: rotate(180deg);
}
.layout-main .btn-toggle-sidebar:hover {
  color: #434346;
}

.sidebar-toggle-btn {
  margin-right: 4px;
  margin-left: 4px;
}

.page-title .title-area .indicator-pill {
  margin-top: 0px;
}

.page-title .title-area .title-text {
  font-size: 16px;
  margin-top: 2px;
  margin-left: 5px;
}

.btn-toggle-sidebar {
  background: var(--primary);
  border: 0px;
  margin: 0px 0px 0px 0px;
  height: 30px;
  width: 30px;
  min-width: 30px;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  padding: 0;
  position: relative;
  top: 0px;
  color: #ffffff;
  border-radius: 5px;
  transition: all 0.2s ease-in;
}
.btn-toggle-sidebar:hover {
  background: var(--primary);
}

.btn-toggle-filters {
  border: 0px;
  margin: 0px 10px 0px 0px;
  height: 30px;
  width: 30px;
  display: none;
  min-width: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  padding: 0 !important;
  position: relative;
  top: 0px;
  border-radius: 5px;
  transition: all 0.2s ease-in;
}

.form-sidebar .sidebar-image-section .sidebar-image {
  max-height: 100%;
}

.page-form .filter-button.btn-primary-light {
  color: #000000;
}

.filter-icon.active use {
  stroke: #000000;
}

.layout-main-section-wrapper {
  margin-bottom: 0px;
}

.widget .widget-head .widget-label .widget-title {
  font-weight: 700;
  font-size: 16px;
}

.widget.onboarding-widget-box {
  border: 1px solid rgba(207, 210, 215, 0.58);
  padding: 15px;
  border-radius: 10px;
}

.widget.onboarding-widget-box .widget-body .onboarding-step.active, .widget.onboarding-widget-box .widget-body .onboarding-step:hover {
  background-color: #daecff;
}

.widget.shortcut-widget-box {
  border: 1px solid #ccc;
  box-shadow: 0px 3px 10px -5px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 10px 12px;
  transition: all 0.2s ease-in-out;
}
.widget.shortcut-widget-box .widget-title {
  transition: all 0.2s ease-in-out;
  color: var(--blue-600);
}
.widget.shortcut-widget-box ~ .divider {
  display: none;
}
.widget.shortcut-widget-box:hover {
  border-color: var(--primary);
}
.widget.shortcut-widget-box:hover svg.icon-xs {
  stroke: var(--primary) !important;
}
.widget.shortcut-widget-box:hover .widget-title {
  color: var(--primary) !important;
}

.widget.number-widget-box {
  height: 135px;
  border: 1px solid #dddddd;
}

.widget.number-widget-box .widget-body .widget-content {
  padding-top: 20px;
}

.widget.dashboard-widget-box {
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0px 3px 10px -5px rgba(0, 0, 0, 0.2);
}
.widget.dashboard-widget-box .widget-head {
  background: #fff;
  border: 1px solid #ddd;
  padding: 14px 15px 10px 15px;
  margin: -8px;
  border-radius: 7px 7px 0px 0px;
}
.widget.dashboard-widget-box.edit-mode .widget-head {
  margin: -7px -12px;
}

.number-widget-box {
  background: #fff;
  box-shadow: 0px 3px 10px -5px rgba(0, 0, 0, 0.2);
  border: 1px solid #ddd;
}

.widget.links-widget-box {
  border: 1px solid #ddd;
  box-shadow: 0px 3px 10px -5px rgba(0, 0, 0, 0.2);
}
.widget.links-widget-box .widget-head {
  background: #fff;
  border: 1px solid #ddd;
  padding: 14px 15px 10px 15px;
  margin: -11px -11px 0px -11px;
  border-radius: 7px 7px 0px 0px;
}
.widget.links-widget-box .link-item {
  font-weight: 600;
}
.widget.links-widget-box.edit-mode .widget-head {
  margin: -7px -12px;
}

.layout-main-section .frappe-list .result, .layout-main-section .frappe-list .no-result, .layout-main-section .frappe-list .freeze, .layout-main-section .report-wrapper .result, .layout-main-section .report-wrapper .no-result, .layout-main-section .report-wrapper .freeze {
  min-height: calc(100vh - 365px);
}

.form-tabs-sticky-down {
  top: calc(var(--navbar-height) + 109px);
  border: 1px solid #d5d5d5;
  margin-right: -1px;
  margin-left: -1px;
  margin-top: -1px;
  z-index: 6;
}
.form-tabs-sticky-down:after {
  background: rgb(248, 250, 251);
  width: 1px;
  height: 7px;
  position: absolute;
  top: -2px;
  left: -1px;
  z-index: -8;
  border-radius: 22px;
  content: "";
}
.form-tabs-sticky-down:before {
  background: rgb(248, 250, 251);
  width: 1px;
  height: 7px;
  position: absolute;
  top: -2px;
  right: -1px;
  z-index: -8;
  border-radius: 22px;
  content: "";
}

.form-tabs-sticky-up {
  top: calc(var(--navbar-height) + 109px);
  border: 1px solid #d5d5d5;
  margin-right: -1px;
  margin-left: -1px;
  margin-top: -1px;
  z-index: 6;
}
.form-tabs-sticky-up:after {
  background: rgb(248, 250, 251);
  width: 1px;
  height: 7px;
  position: absolute;
  top: -2px;
  left: -1px;
  z-index: -8;
  border-radius: 22px;
  content: "";
}
.form-tabs-sticky-up:before {
  background: rgb(248, 250, 251);
  width: 1px;
  height: 7px;
  position: absolute;
  top: -2px;
  right: -1px;
  z-index: -8;
  border-radius: 22px;
  content: "";
}

.form-sidebar .sidebar-image-section {
  width: 100%;
}

.frappe-control[data-fieldtype=Icon] .selected-icon {
  top: 50%;
  font-size: 18px;
}

.search-bar .awesomplete input {
  height: 36px;
}

.list-row .select-like, .list-row-head .select-like {
  padding: 10px 0 10px 6px;
}

.control-label, .checkbox .label-area {
  font-size: 14px;
}

.checkbox .label-area {
  cursor: pointer;
}

.form-section .section-head, .form-dashboard-section .section-head {
  background: var(--gray-50);
  border: 1px solid var(--gray-300);
  font-size: 18px;
  font-weight: 700;
  color: var(--gray-700);
  margin: 6px !important;
  padding: 12px 15px;
  border-radius: 7px;
}
.form-section .section-head ~ .section-body, .form-dashboard-section .section-head ~ .section-body {
  padding-top: 10px;
}

.modal .form-section .section-head, .form-in-grid .form-section .section-head {
  padding: 10px;
  margin: 6px 0px !important;
  font-weight: 700;
}

.grid-heading-row {
  background: var(--gray-50);
  border-radius: 5px 5px 0px 0px;
}

#freeze.in {
  background-color: rgba(240, 240, 240, 0.7);
  opacity: 1;
}

#freeze .freeze-message-container {
  background-color: transparent;
}
#freeze .freeze-message-container .lead {
  font-weight: 600;
  color: #000000;
  display: flex;
  align-items: center;
}
#freeze .freeze-message-container .lead:before {
  border: 4px solid #dddddd;
  border-top: 4px solid var(--primary);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: loader-spin 0.6s linear infinite;
  margin: 0px 8px;
  content: "";
}

.icon-picker .icons .icon-wrapper {
  font-size: 20px;
  opacity: 0.8;
  color: #444;
  border: 1px solid #aaa;
  border-radius: 4px;
  margin: 2.4px 2px;
  transition: all 0.2s ease-in-out;
}
.icon-picker .icons .icon-wrapper:hover {
  border-color: #444444;
  color: #000000;
  opacity: 1;
}
.icon-picker .icons .icon-wrapper > i {
  display: block;
  margin: 0px auto;
}

#navbar-breadcrumbs {
  font-size: 14px;
  display: block;
}
#navbar-breadcrumbs > li {
  display: inline-block;
}
#navbar-breadcrumbs > li > a {
  font-size: 16px;
  font-weight: 600;
}
#navbar-breadcrumbs > li:first-child > a:before {
  display: none;
}
#navbar-breadcrumbs > li.disabled > a {
  color: #888888;
}

.navbar .navbar-nav .nav-link {
  padding: 10px 0px;
}

.form-tabs-list .form-tabs {
  background: var(--blue-50);
  margin: 10px;
  border-radius: 7px;
  padding: 0px 12px;
  overflow: hidden;
}
.form-tabs-list .form-tabs .nav-item .nav-link {
  background: transparent;
  border-bottom: 1px solid transparent;
  height: 36px;
  line-height: 36px;
  margin: 15px 3px;
  padding: 0px 10px;
  color: #6c7881;
  font-weight: 700;
  position: relative;
  border-radius: 7px;
  transition: all 0.2s ease-in-out;
}
.form-tabs-list .form-tabs .nav-item .nav-link:after {
  border-top: 7px solid transparent;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  width: 0;
  height: 0;
  position: absolute;
  bottom: 7px;
  left: 50%;
  margin-left: -8px;
  visibility: hidden;
  opacity: 0;
  content: "";
}
.form-tabs-list .form-tabs .nav-item .nav-link:hover {
  background: var(--blue-100);
  color: var(--blue-800);
}
.form-tabs-list .form-tabs .nav-item .nav-link.active {
  background: var(--primary) !important;
  color: #ffffff !important;
  font-weight: 700;
  transition: all 0.2s ease-in-out;
}
.form-tabs-list .form-tabs .nav-item .nav-link.active:after {
  border-top: 7px solid var(--primary);
  bottom: -7px;
  visibility: visible;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
.form-tabs-list .btn-save-doc {
  position: absolute;
  right: 25px;
  top: 25px;
  z-index: 25;
}

.modal .modal-header .modal-title {
  font-weight: 700;
  font-size: 18px;
}

div#driver-highlighted-element-stage, div#driver-page-overlay, div#driver-popover-item {
  display: none !important;
}

.tree-node.opened {
  position: relative;
}

.tree.with-skeleton {
  position: relative;
}
.tree.with-skeleton::before {
  background: #d1d8dd;
  width: 1px;
  position: absolute;
  top: 40px;
  left: 18px;
  bottom: 24px;
  z-index: 1;
  content: "";
}

.tree-node::before {
  background: #d1d8dd;
  width: 1px;
  position: absolute;
  top: 30px;
  left: 12px;
  bottom: 16px;
  z-index: 1;
  content: "";
}
.tree-node.open::after {
  background: #d1d8dd;
  height: 1px;
  position: absolute;
  left: 13px;
  right: calc(100% - 27px);
  bottom: 12px;
  z-index: 0;
  content: "";
}
.tree-node .tree-link {
  position: relative;
}
.tree-node .tree-link::after {
  background: #d1d8dd;
  height: 1px;
  position: absolute;
  left: -17px;
  right: calc(100% - 1px);
  bottom: 16px;
  z-index: 0;
  content: "";
}

.form-section.card-section, .form-dashboard-section {
  border-bottom: 1px solid #d5d5d5;
}

.form-grid {
  border: 0;
}
.form-grid .grid-heading-row {
  border: 1px solid #d2dbe1;
}
.form-grid .grid-heading-row .grid-static-col, .form-grid .grid-heading-row .row-index {
  height: 32px;
  padding: 2px 8px !important;
}
.form-grid .grid-heading-row .row-check {
  padding: 5px 8px !important;
}
.form-grid .grid-body {
  border: 1px solid #d2dbe1;
  border-top: 0;
}

.grid-row {
  border-bottom: 1px solid #d2dbe1;
}

.grid-row > .row .col, .form-section .grid-row > .section-body .col, .form-dashboard-section .grid-row > .section-body .col, .grid-row > .dialog-assignment-row .col {
  border-right: 1px solid #d2dbe1;
}

.tree-link .node-parent {
  position: relative;
  z-index: 5;
  background: #fff;
}

.desk-alert.blue {
  --toast-bg: #99ceff;
}

.desk-alert.green {
  --toast-bg: #a9f7c7;
}

.desk-alert.red {
  --toast-bg: #ffbfbf;
}

.driver-highlighted-element {
  z-index: 10 !important;
}

.grid-heading-row .grid-static-col, .grid-heading-row .grid-row .row-index {
  font-size: 14px;
  font-weight: 700;
  color: #777777;
  padding-top: 4px;
  line-height: initial;
}

.layout-main-section .standard-filter-section .form-control {
  height: 31.5px !important;
}
.layout-main-section .standard-filter-section .select-icon.xs {
  top: 5px !important;
}
.layout-main-section .standard-filter-section .placeholder.xs {
  top: 6px !important;
}

.col-lg-2.layout-side-section {
  padding-right: 15px;
  margin-left: 0px;
}
.col-lg-2.layout-side-section .list-sidebar .sidebar-section {
  background: #ffffff;
  border: 1px solid #d5d5d5;
  margin-top: 1px;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 7px;
  transition: all 0.4s ease-in-out;
}
.col-lg-2.layout-side-section .list-sidebar .sidebar-section > li.sidebar-label {
  background: #ffffff;
  border-bottom: 1px solid #d5d5d5;
  margin: -15px -15px 0px -15px;
  color: #463e34;
  font-size: 13px;
  font-weight: 700;
  padding: 8px 15px;
  position: relative;
  align-items: center;
  cursor: pointer;
  border-radius: 5px 5px 0px 0px;
}
.col-lg-2.layout-side-section .list-sidebar .sidebar-section > li.sidebar-label:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  position: absolute;
  font-size: 18px;
  top: 8px;
  right: 8px;
  content: "\f0de";
}
.col-lg-2.layout-side-section .list-sidebar .sidebar-section > li.sidebar-label:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  position: absolute;
  font-size: 18px;
  top: 0px;
  right: 8px;
  content: "\f0dd";
  display: none;
}
.col-lg-2.layout-side-section .list-sidebar .sidebar-section.hide-content {
  height: 37px;
  overflow: hidden;
}
.col-lg-2.layout-side-section .list-sidebar .sidebar-section.hide-content > li.sidebar-label:after {
  display: none;
}
.col-lg-2.layout-side-section .list-sidebar .sidebar-section.hide-content > li.sidebar-label:before {
  display: block;
}
.col-lg-2.layout-side-section .navbar {
  box-shadow: none;
  border-bottom: 1px solid #d5d5d5;
}
.col-lg-2.layout-side-section .form-sidebar > .sidebar-menu {
  background: #ffffff;
  border: 1px solid #d5d5d5;
  margin-top: 1px;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 7px;
}
.col-lg-2.layout-side-section .form-sidebar > .sidebar-menu > li.sidebar-label {
  background: #ffffff;
  border-bottom: 1px solid #d5d5d5;
  margin: -15px -15px 15px -15px;
  color: #463e34;
  font-size: 13px;
  font-weight: 700;
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 5px 5px 0px 0px;
}
.col-lg-2.layout-side-section .form-sidebar > .sidebar-menu > li.sidebar-label:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  position: absolute;
  font-size: 18px;
  top: 8px;
  right: 8px;
  content: "\f0de";
}
.col-lg-2.layout-side-section .form-sidebar > .sidebar-menu > li.sidebar-label:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  position: absolute;
  font-size: 18px;
  top: 0px;
  right: 8px;
  content: "\f0dd";
  display: none;
}
.col-lg-2.layout-side-section .form-sidebar > .sidebar-menu > li.sidebar-label.hidden {
  display: block !important;
}
.col-lg-2.layout-side-section .form-sidebar > .sidebar-menu.hide-content {
  height: 37px;
  overflow: hidden;
}
.col-lg-2.layout-side-section .form-sidebar > .sidebar-menu.hide-content > li.sidebar-label:after {
  display: none;
}
.col-lg-2.layout-side-section .form-sidebar > .sidebar-menu.hide-content > li.sidebar-label:before {
  display: block;
}

.page-form {
  border-color: #d5d5d5;
  border-top: 1px solid #dddddd;
}
.page-form .filter-section {
  margin-left: auto;
}

.attachment-row .data-pill, .form-tag-row .data-pill {
  background-color: var(--blue-50);
}

.followed-by-section, .form-sidebar-stats {
  display: none;
}

.recent-item.notification-item .notification-body {
  display: flex;
  align-items: inherit;
}

.datepicker--cell.-selected-, .datepicker--cell.-current-.-selected- {
  background: var(--primary);
}

.fc-unthemed .fc-today .fc-day-number {
  background-color: var(--primary);
}

.fc-view > table {
  background: var(--blue-50);
  border: 1px solid var(--primary);
}

.fc th {
  background: var(--primary);
  border: 1px solid var(--primary) !important;
  font-weight: 700;
  color: #ffffff;
  font-size: 18px;
}

.fc td {
  font-weight: 600;
  font-size: 18px;
  color: #666666;
}

[frappe-session-status=logged-out] .navbar {
  display: none;
}

.page-container[id^=page-List] .layout-main-section.frappe-card {
  overflow: visible;
}
.page-container[id^=page-List] .btn-toggle-filters {
  display: inline-block;
}

.app-loading {
  background-color: #f8fafb;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999999999;
}
.app-loading img {
  display: inline-block;
}
.app-loading > .loader-img {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -28px;
  margin-top: -29.5px;
}
.app-loading > .loader-svg {
  width: 135px;
  height: 135px;
}

html[lang=ar] .sidebar-image-section .dropdown-menu {
  right: auto !important;
  left: 80px !important;
}
html[lang=ar] .side-menu .side-menu-items > ul.shortcuts-list > li > a > i {
  transform: rotate(-270deg);
}
html[lang=ar] .frappe-control .action-btn, html[lang=ar] .frappe-control .link-btn {
  transform: rotate(-180deg);
}

div[id=page-Workspaces] .page-head {
  padding: 12px 0px 0px 10px;
}
div[id=page-Workspaces] .page-head .page-actions {
  margin: 0px 20px 0px 10px;
}
div[id=page-Workspaces] .page-head:after {
  display: none;
}
div[id=page-Workspaces] .btn-toggle-sidebar {
  display: none;
}
div[id=page-Workspaces] .page-title .title-area .title-text {
  margin-top: 2px;
  margin-left: 5px;
}

.layout-main-section.frappe-card {
  box-shadow: none;
  border: 1px solid #d5d5d5;
}

[data-page-route=Workspaces] .layout-main-section {
  box-shadow: none !important;
  border: 1px solid #d5d5d5;
  border-radius: 7px;
}

[data-page-route=Workspaces] .layout-main-section-wrapper {
  margin-top: -8px;
  padding-top: 0px;
}

[id=page-dashboard-view] .page-content {
  padding-bottom: 1px;
}
[id=page-dashboard-view] .widget-group {
  margin-bottom: 20px;
}

[id^="page-List/"] .layout-main {
  margin-top: 5px;
}

.widget.onboarding-widget-box {
  margin-top: -5px;
}

.std-form-layout > .form-layout > .form-page {
  box-shadow: none;
  border: 1px solid #d5d5d5;
  margin-bottom: 20px;
}

.form-tabs-list {
  border: 1px solid #d5d5d5;
  padding: 0px;
  margin-right: -1px;
  margin-left: -1px;
  margin-top: -1px;
}

button.data-pill.btn.add-attachment-btn {
  background: #fff;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  color: #666666;
}
button.data-pill.btn.add-attachment-btn:hover {
  background: rgba(235, 239, 243, 0.7);
  border-color: #b5b5b5;
  color: #333333;
}

.bootstrap-select > .dropdown-toggle {
  background: #fff !important;
  border: 2px solid #d2dbe1 !important;
  padding: 9.5px 8px !important;
  outline: 0 !important;
}
.bootstrap-select > .dropdown-toggle:hover {
  background: rgba(235, 239, 243, 0.5) !important;
  border-color: #a2a8b1 !important;
}
.bootstrap-select > .dropdown-toggle:active {
  background: rgba(235, 239, 243, 0.5) !important;
  border-color: var(--primary) !important;
}
.bootstrap-select > .dropdown-toggle[aria-expanded=true] {
  background: rgba(235, 239, 243, 0.5) !important;
  border-color: var(--primary) !important;
}
.bootstrap-select > .dropdown-toggle.border-danger {
  border-color: #e24c4c !important;
}
.bootstrap-select .bs-actionsbox, .bootstrap-select .bs-donebutton, .bootstrap-select .bs-searchbox {
  padding: 2px 2px 6px 2px !important;
}
.bootstrap-select .bs-searchbox .form-control {
  height: 28px;
}
.bootstrap-select .dropdown-item.active, .bootstrap-select .dropdown-item:active {
  background-color: #d6ecff;
  color: #1b62a1 !important;
}
.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
  top: 12px !important;
}

.bootstrap-select .dropdown-toggle:focus, .bootstrap-select > select.mobile-device:focus + .dropdown-toggle {
  outline: 0 !important;
  outline: 0px auto -webkit-focus-ring-color !important;
}

.vanilla-settings-country-flag-option {
  display: flex;
  align-items: center;
}
.vanilla-settings-country-flag-option > img {
  height: 21px;
  margin-right: 10px;
}

.form-layout input[type=checkbox], .form-layout input[type=radio] {
  width: 20px !important;
  height: 20px;
}

.form-grid-container input[type=checkbox], .form-grid-container input[type=radio] {
  width: 15px !important;
  height: 15px;
}

div#page-dashboard-view {
  padding-right: 0px;
}

.dashboard-graph {
  margin-top: 6px;
}

[onclick="return frappe.ui.toolbar.toggle_full_width()"], [onclick="return new frappe.ui.ThemeSwitcher().show()"] {
  display: none !important;
}

fieldset.vue-fieldset {
  border: 1px solid #cccccc !important;
  border-radius: 8px !important;
  color: #666666 !important;
}
fieldset.vue-fieldset legend {
  background: #f8f9fb;
  width: auto;
  font-size: 16px;
  font-weight: 700;
  margin: 0px 10px;
  padding: 0px 10px;
}

.page-actions .btn:not(.icon-btn), .page-actions div#driver-popover-item .driver-popover-footer button:not(.icon-btn), div#driver-popover-item .driver-popover-footer .page-actions button:not(.icon-btn) {
  padding: 18px 12px;
  font-size: 14px;
}

.page-actions .btn:not(.btn-md):not(.btn-lg), .page-actions div#driver-popover-item .driver-popover-footer button:not(.btn-md):not(.btn-lg) {
  padding: 18px 12px;
}

.new-timeline .activity-title, .new-timeline .timeline-actions {
  background-color: #fafafa;
}

.form-footer .scroll-to-top {
  bottom: 0;
}

[data-page-route=Workspaces] .codex-editor {
  min-height: 620px;
}

.layout-main {
  min-height: calc(100vh - 230px);
}
.layout-main:has(.frappe-list) {
  margin-bottom: 5px;
}

.control-input.flex.align-center.form-control.fields_order {
  height: 32px;
  line-height: 27px;
  font-size: 14px;
}

.table-multiselect {
  padding: 0 0 5px 5px !important;
}

.table-multiselect .awesomplete {
  width: auto;
  display: inline;
}

.table-multiselect .tb-selected-value {
  margin: 5px 5px 0 0;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* START TOOLTIP STYLES */
[tooltip] {
  position: relative; /* opinion 1 */
}

/* Applies to all tooltips */
[tooltip]::before,
[tooltip]::after {
  text-transform: none; /* opinion 2 */
  font-family: inherit !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}

[tooltip]::before {
  content: "";
  border: 5px solid transparent; /* opinion 4 */
  z-index: 1001; /* absurdity 1 */
}

[tooltip]::after {
  content: attr(tooltip); /* magic! */
  /* most of the rest of this is opinion */
  font-family: Helvetica, sans-serif;
  text-align: center;
  /*
    Let the content set the size of the tooltips
    but this will also keep them from being obnoxious
  */
  min-width: 3em;
  max-width: 21em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1ch 1.5ch;
  border-radius: 5px;
  box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
  background: #333;
  color: #fff;
  z-index: 1000; /* absurdity 2 */
}

/* Make the tooltips respond to hover */
[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
}

/* don't show empty tooltips */
[tooltip=""]::before,
[tooltip=""]::after {
  display: none !important;
}

/* FLOW: UP */
[tooltip]:not([flow])::before,
[tooltip][flow^=up]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #333;
}

[tooltip]:not([flow])::after,
[tooltip][flow^=up]::after {
  bottom: calc(100% + 5px);
}

[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^=up]::before,
[tooltip][flow^=up]::after {
  left: 50%;
  transform: translate(-50%, -0.5em);
}

/* FLOW: DOWN */
[tooltip][flow^=down]::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: #333;
}

[tooltip][flow^=down]::after {
  top: calc(100% + 5px);
}

[tooltip][flow^=down]::before,
[tooltip][flow^=down]::after {
  left: 50%;
  transform: translate(-50%, 0.5em);
}

/* FLOW: LEFT */
[tooltip][flow^=left]::before {
  top: 50%;
  border-right-width: 0;
  border-left-color: #333;
  left: calc(0em - 5px);
  transform: translate(-0.5em, -50%);
}

[tooltip][flow^=left]::after {
  top: 50%;
  right: calc(100% + 5px);
  transform: translate(-0.5em, -50%);
}

/* FLOW: RIGHT */
[tooltip][flow^=right]::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: #333;
  right: calc(0em - 5px);
  transform: translate(0.5em, -50%);
}

[tooltip][flow^=right]::after {
  top: 50%;
  left: calc(100% + 5px);
  transform: translate(0.5em, -50%);
}

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}
@keyframes tooltips-horz {
  to {
    opacity: 1;
    transform: translate(0, -50%);
  }
}
/* FX All The Things */
[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^=up]:hover::before,
[tooltip][flow^=up]:hover::after,
[tooltip][flow^=down]:hover::before,
[tooltip][flow^=down]:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}

[tooltip][flow^=left]:hover::before,
[tooltip][flow^=left]:hover::after,
[tooltip][flow^=right]:hover::before,
[tooltip][flow^=right]:hover::after {
  animation: tooltips-horz 300ms ease-out forwards;
}

.side-menu {
  background: var(--primary);
  background: linear-gradient(355deg, var(--blue-600) 0%, var(--primary) 100%);
  box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.1), 0 4px 20px 0 rgba(0, 0, 0, 0.15);
  width: 250px;
  position: fixed;
  top: 80px;
  bottom: 20px;
  left: 20px;
  border-radius: 7px;
  z-index: 200;
}
.side-menu .side-modules-list {
  padding: 15px;
  margin: 0;
  list-style: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}
.side-menu .side-modules-list > li {
  display: block;
  margin: 0px 0px 10px 0px;
}
.side-menu .side-modules-list > li > a {
  display: flex;
  width: 100%;
  height: 46px;
  padding: 0px 15px;
  align-items: center;
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  border-radius: 7px;
  transition: all 0.2s ease-in-out;
}
.side-menu .side-modules-list > li > a > span {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.side-menu .side-modules-list > li > a > i {
  margin-right: 10px;
  transition: all 0.2s ease-in-out;
}
.side-menu .side-modules-list > li > a:hover {
  background: rgba(0, 0, 0, 0.1);
  color: #fff;
}
.side-menu .side-modules-list > li > a:hover > i {
  margin-right: 15px;
}
.side-menu .side-modules-list > li.active > a {
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  font-weight: 600;
}
.side-menu .side-modules-tree {
  padding: 15px;
  margin: 0;
  list-style: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}
.side-menu .side-modules-tree > li {
  display: block;
  margin: 0px 0px 10px 0px;
}
.side-menu .side-modules-tree > li > a {
  display: flex;
  width: 100%;
  height: 46px;
  padding: 0px 15px;
  align-items: center;
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  border-radius: 7px;
  transition: all 0.2s ease-in-out;
}
.side-menu .side-modules-tree > li > a > span {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.side-menu .side-modules-tree > li > a > i {
  margin-right: 10px;
  transition: all 0.2s ease-in-out;
}
.side-menu .side-modules-tree > li > a:hover {
  background: rgba(0, 0, 0, 0.1);
  color: #fff;
}
.side-menu .side-modules-tree > li > a:hover > i {
  margin-right: 15px;
}
.side-menu .side-modules-tree > li.has-children > a {
  position: relative;
  cursor: default;
}
.side-menu .side-modules-tree > li.has-children > a > a {
  position: absolute;
  right: 42px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  outline: none !important;
  cursor: pointer;
}
.side-menu .side-modules-tree > li.has-children > a .btn-open-sub-modules {
  background: rgba(0, 0, 0, 0.15);
  border: none;
  outline: none !important;
  width: 30px;
  height: 30px;
  right: 7px;
  color: #eee;
  border-radius: 5px;
  position: absolute;
  cursor: pointer;
  z-index: 10;
  transition: all 0.2s ease-in-out;
}
.side-menu .side-modules-tree > li.has-children > a:hover .btn-open-sub-modules {
  background: rgba(0, 0, 0, 0.25);
}
.side-menu .side-modules-tree > li.has-children > a:hover .btn-open-sub-modules:hover {
  background: rgba(0, 0, 0, 0.35);
  color: #ffffff;
}
.side-menu .side-modules-tree > li.has-children > ul {
  margin: 10px 0px 10px 25px;
  list-style: none;
  padding: 0;
  display: none;
}
.side-menu .side-modules-tree > li.has-children > ul > li {
  display: block;
  margin-bottom: 5px;
}
.side-menu .side-modules-tree > li.has-children > ul > li > a {
  color: #eee;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;
}
.side-menu .side-modules-tree > li.has-children > ul > li > a > i {
  margin-right: 6px;
  display: inline-block;
  transition: all 0.2s ease-in-out;
}
.side-menu .side-modules-tree > li.has-children > ul > li > a:before {
  background: #eee;
  width: 6px;
  height: 2px;
  display: inline-block;
  margin-right: 8px;
  position: relative;
  top: 0px;
  content: "";
  transition: all 0.2s ease-in-out;
}
.side-menu .side-modules-tree > li.has-children > ul > li > a:hover {
  color: #ffffff;
}
.side-menu .side-modules-tree > li.has-children > ul > li > a:hover:before {
  margin-right: 12px;
}
.side-menu .side-modules-tree > li.has-children > ul > li.active > a {
  font-weight: 700;
}
.side-menu .side-modules-tree > li.has-children > ul[data-show=true] {
  display: block;
}
.side-menu .side-modules-tree > li.active-parent > a {
  background: rgba(0, 0, 0, 0.15);
  color: #fff;
  font-weight: 600;
}
.side-menu .side-modules-tree > li.active-parent > a .btn-open-sub-modules > i {
  transform: rotate(-180deg);
}
.side-menu .side-modules-tree > li.active > a {
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  font-weight: 600;
}
body.side-menu-open .app-content-body {
  margin-left: 275px;
}

.vanilla-navbar {
  height: 61px;
  border-bottom: 1px solid #d5d5d5;
}
.vanilla-navbar .navbar-logo {
  width: 215px;
  overflow: hidden;
}
.vanilla-navbar .navbar-logo .navbar-home img {
  max-height: 36px;
  width: auto;
}
.vanilla-navbar .search-bar .btn-close-search {
  background: #ffefef;
  border: 1px solid #ff9595;
  width: 35px;
  height: 35px;
  color: #c00;
  font-size: 18px;
  margin-left: 12px;
  display: none;
  border-radius: 7px;
}
.vanilla-navbar .navbar-mobile-menu {
  background: #fafafa;
  border: 1px solid #ddd;
  padding: 0 !important;
  box-shadow: none !important;
  width: 38px;
  height: 34px;
  display: none;
  margin-right: 20px;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  color: #555;
  border-radius: 7px;
  transition: all 0.2s ease-in-out;
}
.vanilla-navbar .btn-toggle-menu {
  background: #ffffff !important;
  border: 0px !important;
  padding: 0 !important;
  box-shadow: none !important;
  width: 38px;
  height: 38px;
  text-align: center;
  line-height: 38px;
  font-size: 32px;
  color: #aaa;
  transition: all 0.2s ease-in-out;
}
.vanilla-navbar .navbar-breadcrumbs {
  margin-left: 40px !important;
}
.vanilla-navbar .dropdown-open-search {
  margin-right: 2px;
  display: none;
  cursor: pointer;
}
.vanilla-navbar .dropdown-open-search .nav-open-search {
  font-size: 14px;
  color: #666666;
}
.vanilla-navbar .vanilla-navbar-user-dropdown {
  display: flex;
  align-items: center;
}
.vanilla-navbar .vanilla-navbar-user-dropdown > .avatar {
  margin: 0;
}
.vanilla-navbar .vanilla-navbar-user-dropdown > .avatar.avatar-medium {
  width: 32px;
  height: 32px;
}
.vanilla-navbar .vanilla-navbar-user-dropdown > div {
  margin-left: 10px;
  font-size: 13px;
  font-weight: 600;
}
.vanilla-navbar .vanilla-navbar-user-dropdown > div > .logged-user-type {
  display: block;
  font-size: 11px;
  font-weight: 400;
  margin-top: 3px;
}
.vanilla-navbar .vanilla-theme-language-switcher {
  min-width: 80px;
  display: flex;
  align-items: center;
}
.vanilla-navbar .vanilla-theme-language-switcher img {
  width: 28px;
  margin: 0px;
}
.vanilla-navbar .vanilla-theme-language-switcher span {
  display: inline-block;
  margin-left: 6px;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  top: -2px;
}
.vanilla-navbar .vanilla-theme-language-switcher.languages-switcher-flag {
  min-width: 50px;
}
.vanilla-navbar .vanilla-theme-language-switcher.languages-switcher-flag > span {
  display: none;
}
.vanilla-navbar .vanilla-theme-language-switcher.languages-switcher-title {
  min-width: 50px;
}
.vanilla-navbar .vanilla-theme-language-switcher.languages-switcher-title > img {
  display: none;
}
.vanilla-navbar .dropdown-languages-list .dropdown-item {
  display: flex;
  align-items: center;
  width: 100%;
}
.vanilla-navbar .dropdown-languages-list .dropdown-item > img {
  width: 28px;
  margin: 0px;
}
.vanilla-navbar .dropdown-languages-list .dropdown-item span {
  display: inline-block;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  top: -2px;
}
.vanilla-navbar .dropdown-languages-list.languages-switcher-flag {
  width: 70px;
  min-width: 70px;
  max-width: 70px;
}
.vanilla-navbar .dropdown-languages-list.languages-switcher-flag .dropdown-item {
  display: block;
  text-align: center;
}
.vanilla-navbar .dropdown-languages-list.languages-switcher-flag .dropdown-item > span {
  display: none;
}
.vanilla-navbar .dropdown-languages-list.languages-switcher-title {
  width: 180px;
  min-width: 180px;
  max-width: 180px;
}
.vanilla-navbar .dropdown-languages-list.languages-switcher-title .dropdown-item {
  display: block;
}
.vanilla-navbar .dropdown-languages-list.languages-switcher-title .dropdown-item > img {
  display: none;
}

body.side-menu-closed .app-content-body {
  margin-left: 105px;
}
body.side-menu-closed .vanilla-navbar .btn-toggle-menu > i {
  transform: rotate(180deg);
}
body.side-menu-closed .side-menu {
  width: 80px;
  transition: all 0.1s ease-in-out;
}

@media (max-width: 1028px) {
  #navbar-breadcrumbs, .dropdown-help, .dropdown-theme-settings, .vanilla-navbar .vanilla-navbar-user-dropdown > div {
    display: none !important;
  }
  .vanilla-navbar .btn-toggle-menu {
    display: none;
  }
  .app-content-body {
    margin-left: 0 !important;
  }
  .vanilla-navbar .navbar-mobile-menu {
    display: inline-block;
  }
  .vanilla-navbar .navbar-logo .navbar-home img {
    max-height: 30px;
    width: auto;
  }
  .page-actions .btn:not(.btn-md):not(.btn-lg), .page-actions div#driver-popover-item .driver-popover-footer button:not(.btn-md):not(.btn-lg) {
    padding: 10px 8px;
  }
  body {
    --input-height: 32px;
  }
  .like-disabled-input {
    line-height: 32px;
  }
  .frappe-control[data-fieldtype=Link] .link-btn-add .btn.btn-primary {
    height: 32px;
    line-height: 32px;
  }
  .form-tabs-sticky-down, .form-tabs-sticky-up {
    top: calc(var(--navbar-height) + 99px) !important;
  }
  .form-section .section-head, .form-dashboard-section .section-head {
    margin: 6px !important;
    padding: 6px 10px;
  }
  .side-menu {
    position: fixed;
    top: 60px;
    bottom: 0;
    left: -280px;
    visibility: hidden;
    opacity: 0;
    border-radius: 0;
    z-index: 1100;
    transition: all 0.3s ease-in-out;
  }
  .side-menu.hide-on-mobile-menu {
    left: -280px;
    visibility: hidden;
    opacity: 0;
  }
  .side-menu.show-on-mobile-menu {
    left: 0;
    visibility: visible;
    opacity: 1;
  }
  .side-menu .side-modules-tree > li {
    margin-bottom: 5px;
  }
  .side-menu .side-modules-tree > li > a {
    height: 38px;
    padding: 0 12px;
  }
  .side-menu .side-modules-list > li > a > i, .side-menu .side-modules-tree > li > a > i {
    font-size: 16px;
  }
  .side-menu .side-modules-tree > li.has-children > a .btn-open-sub-modules {
    width: 28px;
    height: 26px;
    font-size: 14px;
  }
}
@media (max-width: 820px) {
  div[id=page-Workspaces] .page-head .page-actions {
    margin: 0 20px 0 5px;
  }
  .form-tabs-list .form-tabs {
    overflow: auto;
  }
}
@media (max-width: 768px) {
  .layout-side-section .overlay-sidebar {
    width: 50%;
  }
  .vanilla-navbar .vanilla-theme-language-switcher span {
    display: none;
  }
  .vanilla-navbar .vanilla-theme-language-switcher {
    min-width: 30px;
  }
}
@media (min-width: 700px) {
  .container-sm, .container {
    max-width: 680px;
  }
}
@media (max-width: 680px) {
  .vanilla-navbar .dropdown-open-search {
    display: block;
  }
  .search-bar {
    background: #fff;
    position: absolute;
    left: 0;
    right: 0;
    display: none;
    max-width: 100%;
    z-index: 15;
  }
  .search-bar .awesomplete {
    width: calc(100% - 50px);
  }
  .vanilla-navbar .search-bar .btn-close-search {
    display: block;
  }
  .dropdown-notifications .notifications-list {
    min-width: 70vw;
  }
}
@media (max-width: 600px) {
  .dropdown-notifications .notifications-list {
    min-width: 100vw;
  }
}
@media (max-width: 580px) {
  .search-bar .awesomplete {
    width: calc(100% - 80px);
  }
}
body.layout-full-sidebar .navbar {
  box-shadow: none;
}
body.layout-full-sidebar .navbar .navbar-logo {
  width: 185px;
}
body.layout-full-sidebar .vanilla-navbar .navbar-breadcrumbs {
  margin-left: 15px !important;
}
body.layout-full-sidebar .side-menu {
  box-shadow: none;
  top: 60px;
  bottom: 0;
  left: 0;
  border-radius: 0;
  z-index: 200;
}
body.layout-full-sidebar.side-menu-open .app-content-body {
  margin-left: 250px;
}
body.layout-full-sidebar.side-menu-closed .app-content-body {
  margin-left: 80px;
}
body.layout-full-sidebar .page-head .page-head-content {
  padding: 0px !important;
  margin-top: -1px;
}
body.layout-full-sidebar .page-head {
  padding: 0px;
}
body.layout-full-sidebar .page-head > .container {
  padding: 0px !important;
}
body.layout-full-sidebar .page-head .page-actions {
  margin: 0px 15px 0px 15px;
  border-left: 0px;
  border-right: 0px;
  padding-right: 20px;
  border-radius: 0;
}
body.layout-full-sidebar .page-head:after {
  display: none;
}
body.layout-full-sidebar div[id=page-Workspaces] .page-head {
  padding: 0;
}
body.layout-full-sidebar .layout-main {
  position: relative;
  margin-top: 15px;
}
body.layout-full-sidebar .form-tabs-sticky-up, body.layout-full-sidebar .form-tabs-sticky-down {
  top: calc(var(--navbar-height) + 50px);
}
body.layout-full-sidebar .page-form {
  border-top: 0px;
}

body.layout-light-sidebar .side-menu {
  background: #ffffff;
  border: 1px solid #d5d5d5;
  box-shadow: none;
}
body.layout-light-sidebar .side-menu .side-modules-list > li > a, body.layout-light-sidebar .side-menu .side-modules-tree > li > a {
  color: #36373a;
  transition: all 0.2s ease-in-out;
}
body.layout-light-sidebar .side-menu .side-modules-list > li > a > span, body.layout-light-sidebar .side-menu .side-modules-tree > li > a > span {
  display: inline-block;
  transition: all 0.2s ease-in-out;
}
body.layout-light-sidebar .side-menu .side-modules-list > li > a:hover, body.layout-light-sidebar .side-menu .side-modules-tree > li > a:hover {
  background: rgba(0, 0, 0, 0.05);
  color: #000;
}
body.layout-light-sidebar .side-menu .side-modules-list > li > ul > li > a, body.layout-light-sidebar .side-menu .side-modules-tree > li > ul > li > a {
  color: #36373a;
}
body.layout-light-sidebar .side-menu .side-modules-list > li > ul > li > a:before, body.layout-light-sidebar .side-menu .side-modules-tree > li > ul > li > a:before {
  background: #666666;
}
body.layout-light-sidebar .side-menu .side-modules-list > li > ul > li > a:hover, body.layout-light-sidebar .side-menu .side-modules-tree > li > ul > li > a:hover {
  color: #000;
}
body.layout-light-sidebar .side-menu .side-modules-list > li > ul > li.active > a, body.layout-light-sidebar .side-menu .side-modules-tree > li > ul > li.active > a {
  color: #000;
}
body.layout-light-sidebar .side-menu .side-modules-list > li.active > a, body.layout-light-sidebar .side-menu .side-modules-tree > li.active > a {
  background: var(--primary);
  color: #ffffff;
}
body.layout-light-sidebar.layout-transparent-sidebar .side-menu {
  background: transparent;
  border: none;
  box-shadow: none;
}
body.layout-light-sidebar.layout-full-sidebar .side-menu {
  border-right: 1px solid #d5d5d5;
  border-top: 1px solid #d5d5d5;
}

body.layout-dark-sidebar .side-menu {
  background: #ffffff;
  background: linear-gradient(355deg, #313d49 0%, #252d36 100%);
}
body.layout-dark-sidebar .side-menu .side-modules-list > li > a, body.layout-dark-sidebar .side-menu .side-modules-tree > li > a {
  color: #d3dce3;
  transition: all 0.2s ease-in-out;
}
body.layout-dark-sidebar .side-menu .side-modules-list > li > a > span, body.layout-dark-sidebar .side-menu .side-modules-tree > li > a > span {
  display: inline-block;
  transition: all 0.2s ease-in-out;
}
body.layout-dark-sidebar .side-menu .side-modules-list > li > a:hover, body.layout-dark-sidebar .side-menu .side-modules-tree > li > a:hover {
  background: rgba(0, 0, 0, 0.4);
  color: #ffffff;
}
body.layout-dark-sidebar .side-menu .side-modules-list > li.active > a, body.layout-dark-sidebar .side-menu .side-modules-tree > li.active > a {
  background: var(--primary);
  color: #ffffff;
}

body[data-route^=setup-wizard] .app-content-body {
  padding: 0px 30px !important;
  max-width: 1024px;
  margin: 0px auto;
}
@media (min-width: 768px) {
  body[data-route^=setup-wizard] .container {
    width: 90% !important;
    max-width: 100% !important;
  }
}
body[data-route^=setup-wizard] .slides-wrapper {
  padding: var(--padding-xl) !important;
}

body[data-theme-color=purple] .widget.onboarding-widget-box .widget-body .onboarding-step.active, body[data-theme-color=purple] .widget.onboarding-widget-box .widget-body .onboarding-step:hover {
  background-color: var(--purple-100);
}
body[data-theme-color=purple] .frappe-list .list-row-head {
  background: var(--purple-50);
  border: 1px solid var(--purple-200);
}
body[data-theme-color=purple] .text-primary {
  color: var(--purple) !important;
}
body[data-theme-color=purple] .dropdown-item:hover, body[data-theme-color=purple] .dropdown-item:focus {
  background-color: var(--purple-50);
  color: var(--purple-700);
}
body[data-theme-color=purple] .dropdown-item.active, body[data-theme-color=purple] .dropdown-item:active {
  background-color: var(--purple-600);
  color: #ffffff !important;
}
body[data-theme-color=purple] .page-form .filter-button.btn-primary-light {
  color: var(--text-on-purple);
  outline: 1px solid var(--bg-purple);
}
body[data-theme-color=purple] .btn-primary-light {
  background-color: var(--purple-100);
  border-color: var(--purple-100);
}
body[data-theme-color=purple] .btn-primary-light:not(:disabled):not(.disabled):active, body[data-theme-color=purple] .btn-primary-light:not(:disabled):not(.disabled).active, body[data-theme-color=purple] .show > .btn-primary-light.dropdown-toggle {
  background-color: var(--purple-50);
  border-color: var(--purple-300);
}
body[data-theme-color=purple] .filter-icon.active {
  --icon-stroke: var(--purple-600);
}
body[data-theme-color=purple] .attachment-row .data-pill, body[data-theme-color=purple] .form-tag-row .data-pill {
  background-color: var(--purple-50);
}
body[data-theme-color=purple] .notifications-list .notification-item-tabs .notifications-category.active {
  border-bottom: 1px solid var(--purple);
}
body[data-theme-color=purple] .form-tabs-list .form-tabs {
  background: var(--purple-50);
}
body[data-theme-color=purple] .form-tabs-list .form-tabs .nav-item .nav-link:hover {
  background: var(--purple-100);
  color: var(--purple-800);
}
body[data-theme-color=purple] .btn-primary:not(:disabled):not(.disabled):active, body[data-theme-color=purple] div#driver-popover-item .driver-popover-footer button.driver-next-btn:not(:disabled):not(.disabled):active, body[data-theme-color=purple] .btn-primary:not(:disabled):not(.disabled).active, body[data-theme-color=purple] div#driver-popover-item .driver-popover-footer button.driver-next-btn:not(:disabled):not(.disabled).active, body[data-theme-color=purple] .show > .btn-primary.dropdown-toggle, body[data-theme-color=purple] div#driver-popover-item .driver-popover-footer .show > button.dropdown-toggle.driver-next-btn {
  background-color: var(--purple-700);
  border-color: var(--purple-700);
}
body[data-theme-color=purple] .form-control:focus {
  box-shadow: inset 1px 1px 7px -4px var(--purple-300), 0 1px 10px -3px var(--purple-200) !important;
}

body[data-theme-color=green] .widget.onboarding-widget-box .widget-body .onboarding-step.active, body[data-theme-color=green] .widget.onboarding-widget-box .widget-body .onboarding-step:hover {
  background-color: var(--green-100);
}
body[data-theme-color=green] .frappe-list .list-row-head {
  background: var(--green-50);
  border: 1px solid var(--green-200);
}
body[data-theme-color=green] .text-primary {
  color: var(--green) !important;
}
body[data-theme-color=green] .dropdown-item:hover, body[data-theme-color=green] .dropdown-item:focus {
  background-color: var(--green-50);
  color: var(--green-700);
}
body[data-theme-color=green] .dropdown-item.active, body[data-theme-color=green] .dropdown-item:active {
  background-color: var(--green-600);
  color: #ffffff !important;
}
body[data-theme-color=green] .page-form .filter-button.btn-primary-light {
  color: var(--text-on-green);
  outline: 1px solid var(--bg-green);
}
body[data-theme-color=green] .btn-primary-light {
  background-color: var(--green-100);
  border-color: var(--green-100);
}
body[data-theme-color=green] .btn-primary-light:not(:disabled):not(.disabled):active, body[data-theme-color=green] .btn-primary-light:not(:disabled):not(.disabled).active, body[data-theme-color=green] .show > .btn-primary-light.dropdown-toggle {
  background-color: var(--green-50);
  border-color: var(--green-300);
}
body[data-theme-color=green] .filter-icon.active {
  --icon-stroke: var(--green-600);
}
body[data-theme-color=green] .attachment-row .data-pill, body[data-theme-color=green] .form-tag-row .data-pill {
  background-color: var(--green-50);
}
body[data-theme-color=green] .notifications-list .notification-item-tabs .notifications-category.active {
  border-bottom: 1px solid var(--green);
}
body[data-theme-color=green] .form-tabs-list .form-tabs {
  background: var(--green-50);
}
body[data-theme-color=green] .form-tabs-list .form-tabs .nav-item .nav-link:hover {
  background: var(--green-100);
  color: var(--green-800);
}
body[data-theme-color=green] .btn-primary:not(:disabled):not(.disabled):active, body[data-theme-color=green] div#driver-popover-item .driver-popover-footer button.driver-next-btn:not(:disabled):not(.disabled):active, body[data-theme-color=green] .btn-primary:not(:disabled):not(.disabled).active, body[data-theme-color=green] div#driver-popover-item .driver-popover-footer button.driver-next-btn:not(:disabled):not(.disabled).active, body[data-theme-color=green] .show > .btn-primary.dropdown-toggle, body[data-theme-color=green] div#driver-popover-item .driver-popover-footer .show > button.dropdown-toggle.driver-next-btn {
  background-color: var(--green-600);
  border-color: var(--green-600);
}
body[data-theme-color=green] .form-control:focus {
  box-shadow: inset 1px 1px 7px -4px var(--green-300), 0 1px 10px -3px var(--green-200) !important;
}

body[data-theme-color=orange] .widget.onboarding-widget-box .widget-body .onboarding-step.active, body[data-theme-color=orange] .widget.onboarding-widget-box .widget-body .onboarding-step:hover {
  background-color: var(--orange-100);
}
body[data-theme-color=orange] .frappe-list .list-row-head {
  background: var(--orange-50);
  border: 1px solid var(--orange-200);
}
body[data-theme-color=orange] .text-primary {
  color: var(--orange) !important;
}
body[data-theme-color=orange] .dropdown-item:hover, body[data-theme-color=orange] .dropdown-item:focus {
  background-color: var(--orange-50);
  color: var(--orange-700);
}
body[data-theme-color=orange] .dropdown-item.active, body[data-theme-color=orange] .dropdown-item:active {
  background-color: var(--orange-600);
  color: #ffffff !important;
}
body[data-theme-color=orange] .page-form .filter-button.btn-primary-light {
  color: var(--text-on-orange);
  outline: 1px solid var(--bg-orange);
}
body[data-theme-color=orange] .btn-primary-light {
  background-color: var(--orange-100);
  border-color: var(--orange-100);
}
body[data-theme-color=orange] .btn-primary-light:not(:disabled):not(.disabled):active, body[data-theme-color=orange] .btn-primary-light:not(:disabled):not(.disabled).active, body[data-theme-color=orange] .show > .btn-primary-light.dropdown-toggle {
  background-color: var(--orange-50);
  border-color: var(--orange-300);
}
body[data-theme-color=orange] .filter-icon.active {
  --icon-stroke: var(--orange-600);
}
body[data-theme-color=orange] .attachment-row .data-pill, body[data-theme-color=orange] .form-tag-row .data-pill {
  background-color: var(--orange-50);
}
body[data-theme-color=orange] .notifications-list .notification-item-tabs .notifications-category.active {
  border-bottom: 1px solid var(--orange);
}
body[data-theme-color=orange] .form-tabs-list .form-tabs {
  background: var(--orange-50);
}
body[data-theme-color=orange] .form-tabs-list .form-tabs .nav-item .nav-link:hover {
  background: var(--orange-100);
  color: var(--orange-800);
}
body[data-theme-color=orange] .btn-primary:not(:disabled):not(.disabled):active, body[data-theme-color=orange] div#driver-popover-item .driver-popover-footer button.driver-next-btn:not(:disabled):not(.disabled):active, body[data-theme-color=orange] .btn-primary:not(:disabled):not(.disabled).active, body[data-theme-color=orange] div#driver-popover-item .driver-popover-footer button.driver-next-btn:not(:disabled):not(.disabled).active, body[data-theme-color=orange] .show > .btn-primary.dropdown-toggle, body[data-theme-color=orange] div#driver-popover-item .driver-popover-footer .show > button.dropdown-toggle.driver-next-btn {
  background-color: var(--orange-600);
  border-color: var(--orange-600);
}
body[data-theme-color=orange] .form-control:focus {
  box-shadow: inset 1px 1px 7px -4px var(--orange-300), 0 1px 10px -3px var(--orange-200) !important;
}

body[data-theme-color=yellow] .widget.onboarding-widget-box .widget-body .onboarding-step.active, body[data-theme-color=yellow] .widget.onboarding-widget-box .widget-body .onboarding-step:hover {
  background-color: var(--yellow-100);
}
body[data-theme-color=yellow] .frappe-list .list-row-head {
  background: var(--yellow-50);
  border: 1px solid var(--yellow-200);
}
body[data-theme-color=yellow] .text-primary {
  color: var(--yellow) !important;
}
body[data-theme-color=yellow] .dropdown-item:hover, body[data-theme-color=yellow] .dropdown-item:focus {
  background-color: var(--yellow-50);
  color: var(--yellow-700);
}
body[data-theme-color=yellow] .dropdown-item.active, body[data-theme-color=yellow] .dropdown-item:active {
  background-color: var(--yellow-600);
  color: #ffffff !important;
}
body[data-theme-color=yellow] .page-form .filter-button.btn-primary-light {
  color: var(--text-on-yellow);
  outline: 1px solid var(--bg-yellow);
}
body[data-theme-color=yellow] .btn-primary-light {
  background-color: var(--yellow-100);
  border-color: var(--yellow-100);
}
body[data-theme-color=yellow] .btn-primary-light:not(:disabled):not(.disabled):active, body[data-theme-color=yellow] .btn-primary-light:not(:disabled):not(.disabled).active, body[data-theme-color=yellow] .show > .btn-primary-light.dropdown-toggle {
  background-color: var(--yellow-50);
  border-color: var(--yellow-300);
}
body[data-theme-color=yellow] .filter-icon.active {
  --icon-stroke: var(--yellow-600);
}
body[data-theme-color=yellow] .attachment-row .data-pill, body[data-theme-color=yellow] .form-tag-row .data-pill {
  background-color: var(--yellow-50);
}
body[data-theme-color=yellow] .notifications-list .notification-item-tabs .notifications-category.active {
  border-bottom: 1px solid var(--yellow);
}
body[data-theme-color=yellow] .form-tabs-list .form-tabs {
  background: var(--yellow-50);
}
body[data-theme-color=yellow] .form-tabs-list .form-tabs .nav-item .nav-link:hover {
  background: var(--yellow-100);
  color: var(--yellow-800);
}
body[data-theme-color=yellow] .btn-primary:not(:disabled):not(.disabled):active, body[data-theme-color=yellow] div#driver-popover-item .driver-popover-footer button.driver-next-btn:not(:disabled):not(.disabled):active, body[data-theme-color=yellow] .btn-primary:not(:disabled):not(.disabled).active, body[data-theme-color=yellow] div#driver-popover-item .driver-popover-footer button.driver-next-btn:not(:disabled):not(.disabled).active, body[data-theme-color=yellow] .show > .btn-primary.dropdown-toggle, body[data-theme-color=yellow] div#driver-popover-item .driver-popover-footer .show > button.dropdown-toggle.driver-next-btn {
  background-color: var(--yellow-500);
  border-color: var(--yellow-500);
}
body[data-theme-color=yellow] .form-control:focus {
  box-shadow: inset 1px 1px 7px -4px var(--yellow-200), 0 1px 10px -3px var(--yellow-100) !important;
}

body[data-theme-color=red] .widget.onboarding-widget-box .widget-body .onboarding-step.active, body[data-theme-color=red] .widget.onboarding-widget-box .widget-body .onboarding-step:hover {
  background-color: var(--red-100);
}
body[data-theme-color=red] .frappe-list .list-row-head {
  background: var(--red-50);
  border: 1px solid var(--red-200);
}
body[data-theme-color=red] .text-primary {
  color: var(--red) !important;
}
body[data-theme-color=red] .dropdown-item:hover, body[data-theme-color=red] .dropdown-item:focus {
  background-color: var(--red-50);
  color: var(--red-700);
}
body[data-theme-color=red] .dropdown-item.active, body[data-theme-color=red] .dropdown-item:active {
  background-color: var(--red-600);
  color: #ffffff !important;
}
body[data-theme-color=red] .page-form .filter-button.btn-primary-light {
  color: var(--text-on-red);
  outline: 1px solid var(--bg-red);
}
body[data-theme-color=red] .btn-primary-light {
  background-color: var(--red-100);
  border-color: var(--red-100);
}
body[data-theme-color=red] .btn-primary-light:not(:disabled):not(.disabled):active, body[data-theme-color=red] .btn-primary-light:not(:disabled):not(.disabled).active, body[data-theme-color=red] .show > .btn-primary-light.dropdown-toggle {
  background-color: var(--red-50);
  border-color: var(--red-300);
}
body[data-theme-color=red] .filter-icon.active {
  --icon-stroke: var(--red-600);
}
body[data-theme-color=red] .attachment-row .data-pill, body[data-theme-color=red] .form-tag-row .data-pill {
  background-color: var(--red-50);
}
body[data-theme-color=red] .notifications-list .notification-item-tabs .notifications-category.active {
  border-bottom: 1px solid var(--red);
}
body[data-theme-color=red] .form-tabs-list .form-tabs {
  background: var(--red-50);
}
body[data-theme-color=red] .form-tabs-list .form-tabs .nav-item .nav-link:hover {
  background: var(--red-100);
  color: var(--red-800);
}
body[data-theme-color=red] .btn-primary:not(:disabled):not(.disabled):active, body[data-theme-color=red] div#driver-popover-item .driver-popover-footer button.driver-next-btn:not(:disabled):not(.disabled):active, body[data-theme-color=red] .btn-primary:not(:disabled):not(.disabled).active, body[data-theme-color=red] div#driver-popover-item .driver-popover-footer button.driver-next-btn:not(:disabled):not(.disabled).active, body[data-theme-color=red] .show > .btn-primary.dropdown-toggle, body[data-theme-color=red] div#driver-popover-item .driver-popover-footer .show > button.dropdown-toggle.driver-next-btn {
  background-color: var(--red-700);
  border-color: var(--red-700);
}
body[data-theme-color=red] .form-control:focus {
  box-shadow: inset 1px 1px 7px -4px var(--red-300), 0 1px 10px -3px var(--red-200) !important;
}

body[data-theme-color=pink] .widget.onboarding-widget-box .widget-body .onboarding-step.active, body[data-theme-color=pink] .widget.onboarding-widget-box .widget-body .onboarding-step:hover {
  background-color: var(--pink-100);
}
body[data-theme-color=pink] .frappe-list .list-row-head {
  background: var(--pink-50);
  border: 1px solid var(--pink-200);
}
body[data-theme-color=pink] .text-primary {
  color: var(--pink) !important;
}
body[data-theme-color=pink] .dropdown-item:hover, body[data-theme-color=pink] .dropdown-item:focus {
  background-color: var(--pink-50);
  color: var(--pink-700);
}
body[data-theme-color=pink] .dropdown-item.active, body[data-theme-color=pink] .dropdown-item:active {
  background-color: var(--pink-600);
  color: #ffffff !important;
}
body[data-theme-color=pink] .page-form .filter-button.btn-primary-light {
  color: var(--text-on-pink);
  outline: 1px solid var(--bg-pink);
}
body[data-theme-color=pink] .btn-primary-light {
  background-color: var(--pink-100);
  border-color: var(--pink-100);
}
body[data-theme-color=pink] .btn-primary-light:not(:disabled):not(.disabled):active, body[data-theme-color=pink] .btn-primary-light:not(:disabled):not(.disabled).active, body[data-theme-color=pink] .show > .btn-primary-light.dropdown-toggle {
  background-color: var(--pink-50);
  border-color: var(--pink-300);
}
body[data-theme-color=pink] .filter-icon.active {
  --icon-stroke: var(--pink-600);
}
body[data-theme-color=pink] .attachment-row .data-pill, body[data-theme-color=pink] .form-tag-row .data-pill {
  background-color: var(--pink-50);
}
body[data-theme-color=pink] .notifications-list .notification-item-tabs .notifications-category.active {
  border-bottom: 1px solid var(--pink);
}
body[data-theme-color=pink] .form-tabs-list .form-tabs {
  background: var(--pink-50);
}
body[data-theme-color=pink] .form-tabs-list .form-tabs .nav-item .nav-link:hover {
  background: var(--pink-100);
  color: var(--pink-800);
}
body[data-theme-color=pink] .btn-primary:not(:disabled):not(.disabled):active, body[data-theme-color=pink] div#driver-popover-item .driver-popover-footer button.driver-next-btn:not(:disabled):not(.disabled):active, body[data-theme-color=pink] .btn-primary:not(:disabled):not(.disabled).active, body[data-theme-color=pink] div#driver-popover-item .driver-popover-footer button.driver-next-btn:not(:disabled):not(.disabled).active, body[data-theme-color=pink] .show > .btn-primary.dropdown-toggle, body[data-theme-color=pink] div#driver-popover-item .driver-popover-footer .show > button.dropdown-toggle.driver-next-btn {
  background-color: var(--pink-700);
  border-color: var(--pink-700);
}
body[data-theme-color=pink] .form-control:focus {
  box-shadow: inset 1px 1px 7px -4px var(--pink-300), 0 1px 10px -3px var(--pink-200) !important;
}

[data-theme-mode=dark] .btn.btn-default {
  border: 1px solid #878b93 !important;
}
[data-theme-mode=dark] .btn.btn-default:hover, [data-theme-mode=dark] div#driver-popover-item .driver-popover-footer button.btn-default:hover, [data-theme-mode=dark] .btn.btn-default:active, [data-theme-mode=dark] div#driver-popover-item .driver-popover-footer button.btn-default:active {
  background: var(--fg-hover-color) !important;
  color: var(--text-color) !important;
}
[data-theme-mode=dark] .navbar {
  border-bottom: 1px solid #4b4b4b;
}
[data-theme-mode=dark] .page-container {
  background-color: #161a1f;
}
[data-theme-mode=dark] .page-head {
  background: #1c2126;
}
[data-theme-mode=dark] .page-head:after {
  background-color: #1c2126;
}
[data-theme-mode=dark] .dropdown-item:hover, [data-theme-mode=dark] .dropdown-item:focus {
  color: var(--text-color) !important;
  background-color: var(--fg-hover-color) !important;
}
[data-theme-mode=dark] .form-control {
  color: var(--text-color);
  background-color: var(--control-bg);
  background-clip: padding-box;
  border: 1px solid #6b737b;
}
[data-theme-mode=dark] .form-control:hover {
  border-color: #818c95;
}
[data-theme-mode=dark] .form-control:focus {
  background-color: var(--control-bg) !important;
}
[data-theme-mode=dark] .std-form-layout > .form-layout > .form-page {
  background-color: var(--card-bg);
  border: 1px solid #4b4b4b;
}
[data-theme-mode=dark] .form-section.card-section, [data-theme-mode=dark] .form-dashboard-section {
  border-color: #4b4b4b;
}
[data-theme-mode=dark] .form-section .section-head, [data-theme-mode=dark] .form-dashboard-section .section-head {
  background: #323941;
  border: 1px solid #636a70;
  color: #dfe7f0;
}
[data-theme-mode=dark] .page-head .page-actions {
  background: #323941;
  border: 1px solid #636a70;
}
[data-theme-mode=dark] .col-lg-2.layout-side-section .form-sidebar > .sidebar-menu {
  background: #1c2126;
  border: 1px solid #4b4b4b;
}
[data-theme-mode=dark] .col-lg-2.layout-side-section .form-sidebar > .sidebar-menu > li.sidebar-label {
  background: #1c2126;
  border: 1px solid #4b4b4b;
  color: #bbbbbb;
}
[data-theme-mode=dark] button.data-pill.btn.add-attachment-btn {
  background: #161a1f;
  border: 1px solid #878b93;
  color: #ccc !important;
}
[data-theme-mode=dark] .attachment-row .data-pill, [data-theme-mode=dark] .form-tag-row .data-pill {
  background-color: #323941;
}